<template>
  <div>
    <div class="mask_img">
      <div class="font_sy">
        抱歉，您访问的页面不存在!
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "some_error",
  data(){
    return {
    }
  },

  created() {
  },

  mounted() {
    console.log("im in !!!!!!!")
  }
};
</script>

<style lang="less" scoped>

.mask_img {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  right: 0px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.font_sy {
  font-size: x-large;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
